<template>
  <div v-if="getOutletData !== null && currentInvoice !== null" v-loading="loadingInvoice">
    <el-row type="flex" justify="center" class="pb30">
      <span class="tt-font-gray text-center">Доорх нэхэмжлэхийн дагуу систем ашиглалтын төлбөрөө төлсөнөөр таны гэрээ баталгаажуулалт хийгдсэн тохиолдолд системийн хандах эрх автоматаар сунгагдах болно. <br> Асууж тодруулах зүйл байвал 95015558 дугаарлуу хандана уу! </span>
    </el-row>
    <el-row class="invoice_body" id="invoice">
      <el-row type="flex" justify="space-between" class="invoice_description">
        <el-col :span="16">НХМаягт Т-1</el-col>
        <el-col :span="8">Сангийн сайдын 2017 оны 12 дугаар сарын 5-ны өдрийн 347 тоот тушаалын хавсралт</el-col>
      </el-row>
      <el-row type="flex" justify="center" class="invoice_header"><strong>НЭХЭМЖЛЭХ № 18</strong></el-row>
      <el-row class="mb10">
        <el-col :span="15"><strong>Нэхэмжлэгч:</strong></el-col>
        <el-col :span="9"><strong>Төлөгч:</strong></el-col>
      </el-row>
      <el-row>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">Байгууллагын нэр:</el-col>
              <el-col :span="14" class="border_bottom">ТокТок ХХК</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">НӨАТ төлөгчийн дугаар:</el-col>
              <el-col :span="14" class="border_bottom">6183352</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">Хаяг:</el-col>
              <el-col :span="14" class="border_bottom">Улаанбаатар хот,Хан-Уул дүүрэг, 3-р хороо, Чингисийн өргөн чөлөө, Хан-Уул Та</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">Утас, Факс:</el-col>
              <el-col :span="14" class="border_bottom">345525</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10" type="flex" align="bottom">
              <el-col :span="10">Электрон шуудан:</el-col>
              <el-col :span="14" class="border_bottom">
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mb20">
              <el-col :span="10">Төлбөр хийх хугацаа:</el-col>
              <el-col :span="14" class="border_bottom">{{ generateNowDate() }}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="10">Банкны нэр:</el-col>
              <el-col :span="14" class="border_bottom">ХААН ТӨВ БАНК</el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">Байгууллагын нэр:</el-col>
              <el-col :span="14" class="border_bottom">{{getOutletData.company_name}}</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">НӨТ төлөгчийн дугаар:</el-col>
              <el-col :span="14" class="border_bottom">{{getOutletData.vat_regno}}</el-col>
            </el-row>
            <el-row :gutter="20" class="mb10">
              <el-col :span="10">Хаяг:</el-col>
              <el-col :span="14" class="border_bottom">{{ getOutletData.address }}</el-col>
            </el-row>
            <el-row :gutter="20" class="mb20" type="flex" align="bottom">
              <el-col :span="10">Гэрээний дугаар:</el-col>
              <el-col :span="14" class="border_bottom"></el-col>
            </el-row>
            <el-row :gutter="20" class="mb20">
              <el-col :span="10">Нэхэмжилсэн огноо:</el-col>
              <el-col :span="14" class="border_bottom">{{ generateNowDate() }}</el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="10">Дансны дугаар:</el-col>
              <el-col :span="14" class="border_bottom">5173108943</el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <table>
            <tr>
              <th>№</th>
              <th>Код</th>
              <th>Бараа, ажил үйлчилгээ</th>
              <th>Тоо хэмжээ</th>
              <th>Нэгж</th>
              <th>Нэгж үнэ</th>
              <th>НӨТ-гүй дүн</th>
            </tr>
            <tr>
              <td>1</td>
              <td></td>
              <td></td>
              <td>1</td>
              <td></td>
              <td>{{$helpers.formatPrice(currentInvoice.unit_price)}}</td>
              <td>{{$helpers.formatPrice(currentInvoice.unit_price)}}</td>
            </tr>
          </table>
        </el-row>
        <el-row class="mt5">
          <el-col :span="6" class="ml20">Мөнгөний дүн (үсгээр)</el-col>
          <el-col :span="10">{{ generatePayment() }} төгрөг</el-col>
          <el-col :span="7">
            <el-row class="text-right">
              <el-col :span="12"><strong>Бүх дүн, НӨТ-гүй:</strong></el-col>
              <el-col :span="12">{{$helpers.formatPrice(currentInvoice.unit_price)}}</el-col>
            </el-row>
            <el-row class="mt5 text-right">
              <el-col :span="12"><strong>НӨАТ (10%):</strong></el-col>
              <el-col :span="12">{{$helpers.formatPrice(currentInvoice.noat_price)}}</el-col>
            </el-row>
            <el-row class="mt5 text-right">
              <el-col :span="12"><strong>Нийт дүн:</strong></el-col>
              <el-col :span="12">{{$helpers.formatPrice(currentInvoice.pay_total)}}</el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="mt10">
          <el-col :span="8">(Тамга)</el-col>
          <el-col :span="16">
            <el-row>
              <el-col :span="6">ЕРӨНХИЙ ЗАХИРАЛ</el-col>
              <el-col :span="5">............................</el-col>
              <el-col :span="13">/Ч. Долгорсүрэн/</el-col>
            </el-row>
            <el-row class="mt5">
              <el-col :span="6">ЕРӨНХИЙ НЯ-БО</el-col>
              <el-col :span="5">............................</el-col>
              <el-col :span="13">/Д. Алтанцэцэг/</el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-row>
      <div class="footer-container mt30">
        <el-row>
          <el-button type="warning" class="tt-button-yellow" @click="generate">ХЭВЛЭХ</el-button>
        </el-row>
        <el-row>
          <el-button class="tt-button-yellow" type="text" style="color: gray" @click="goBack">БУЦАХ</el-button>
        </el-row>
      </div>
    </el-row>
  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import dayjs from 'dayjs'
import { toWords } from 'mon_num'
import mAxios from '../../../helper/axiosInstance'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  name: 'invoice',
  data () {
    return {
      getOutletData: null,
      currentInvoice: null,
      loadingInvoice: false
    }
  },
  mounted () {
    if (this.$root.isAdmin) {
      this.getOutletData = this.$root.chosenOutlets[0]
    } else {
      this.getOutletData = this.$root.outlet
    }
    if (this.getOutletData !== null) {
      this.getPartnerInvoice()
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    getPartnerInvoice () {
      this.loadingInvoice = true
      const body = {}
      body.outlet_ids = [this.$route.params.id]
      mAxios.post('outlet/getPartnerInvoice', body).then(servicesResponse => {
        if (servicesResponse.data.status === 'success') {
          this.currentInvoice = servicesResponse.data.data.outlets[0]
          this.loadingInvoice = false
        } else {
          this.$message.error({
            title: 'Алдаа гарлаа',
            message: servicesResponse.data.message,
            type: 'error'
          })
          this.loadingInvoice = false
        }
      })
    },
    generatePayment () {
      const payment = toWords(this.currentInvoice.pay_total, { ucFirst: true, suffix: 'n' })
      return payment
    },
    generateNowDate () {
      const today = dayjs().format('YYYY/MM/DD')
      return today
    },
    generate () {
      const docDefinition = {
        content: [
          {
            columns: [
              { text: 'НХМаягт Т-1', alignment: 'left', style: 'invoice_description' },
              { text: '', alignment: 'left', style: 'invoice_description' },
              { text: 'Сангийн сайдын 2017 оны 12 дугаар сарын 5-ны өдрийн 347 тоот тушаалын хавсралт', alignment: 'right', style: 'invoice_description' }
            ],
            columnGap: 10
          },
          { text: 'НЭХЭМЖЛЭХ № 18', alignment: 'center', style: 'invoice_header', margin: [0, 20, 0, 40] },
          {
            columns: [
              { text: 'Нэхэмжлэгч:', alignment: 'left', style: 'text_bold', margin: [0, 0, 0, 8] },
              { text: 'Төлөгч:', alignment: 'left', style: 'text_bold', margin: [0, 0, 0, 8] }
            ]
          },
          {
            columns: [
              { text: 'Байгууллагын нэр:', alignment: 'left', style: 'text_body' },
              { text: 'ТокТок ХХК', alignment: 'left', style: 'text_body' },
              { text: 'Байгууллагын нэр:', alignment: 'left', style: 'text_body' },
              { text: this.getOutletData.company_name, alignment: 'left', style: 'text_body' }
            ]
          },
          {
            columns: [
              { text: 'НӨТ төлөгчийн дугаар:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '6183352', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: 'НӨТ төлөгчийн дугаар:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: this.getOutletData.vat_regno, alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: 'Хаяг:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: 'Улаанбаатар хот,Хан-Уул дүүрэг,3-р хороо,Чингисийн өргөн чөлөө,Хан-Уул Та', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: 'Хаяг:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: this.getOutletData.address, alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: 'Утас, факс:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '345525', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: 'Гэрээний дугаар:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: 'Электрон шуудан:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: 'Төлбөр хийх хугацаа:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: this.generateNowDate(), alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: 'Нэхэмжилсэн огноо:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: this.generateNowDate(), alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: 'Банкны нэр:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: 'ХААН ТӨВ БАНК', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: 'Дансны дугаар:', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] },
              { text: '5173108943', alignment: 'left', style: 'text_body', margin: [0, 5, 0, 0] }
            ]
          },
          {
            layout: {
              hLineWidth: function (i, node) {
                return (i === i++) ? 1 : 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            },
            table: {
              headerRows: 1,
              widths: ['auto', '*', 'auto', '*', '*', '*', '*'],
              body: [
                [{ text: '№', style: 'text_table_header' }, { text: 'Код', style: 'text_table_header' }, { text: 'Бараа, ажил үйлчилгээ', style: 'text_table_header' }, { text: 'Тоо хэмжээ', style: 'text_table_header' }, { text: 'Нэгж', style: 'text_table_header' }, { text: 'Нэгж үнэ', style: 'text_table_header' }, { text: 'НӨТ-гүй дүн', style: 'text_table_header' }],
                [{ text: 1, style: 'fontSize: 9' }, '', '', { text: 1, style: 'text_table_data' }, '', { text: this.$helpers.formatPrice(this.currentInvoice.unit_price), style: 'text_table_data' }, { text: this.$helpers.formatPrice(this.currentInvoice.unit_price), style: 'text_table_data' }]
              ]
            }
          },
          {
            columns: [
              { text: 'Мөнгөний дүн (үсгээр)', alignment: 'right', style: 'text_body', width: '25%', margin: [0, 5, 0, 0] },
              { text: `${this.generatePayment()} төгрөг`, alignment: 'right', style: 'text_body', width: '40%', margin: [0, 5, 0, 0] },
              { text: 'Бүх дүн, НӨТ-гүй:', alignment: 'right', style: 'text_body_bold', margin: [0, 5, 0, 0] },
              { text: this.$helpers.formatPrice(this.currentInvoice.unit_price), alignment: 'right', style: 'text_body', width: '15%', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: '', alignment: 'center', style: 'text_body' },
              { text: 'НӨАТ(10%):', alignment: 'right', style: 'text_body_bold', margin: [0, 5, 0, 0] },
              { text: this.$helpers.formatPrice(this.currentInvoice.noat_price), alignment: 'right', style: 'text_body', width: '15%', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: '', alignment: 'center', style: 'text_body' },
              { text: 'Нийт дүн:', alignment: 'right', style: 'text_body_bold', margin: [0, 5, 0, 0] },
              { text: this.$helpers.formatPrice(this.currentInvoice.pay_total), alignment: 'right', style: 'text_body', width: '15%', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: '(Тамга)', alignment: 'left', style: 'text_body', width: '30%', margin: [0, 20, 0, 0] },
              { text: 'ЕРӨНХИЙ ЗАХИРАЛ   ......................   /Ч. Долгорсүрэн/', alignment: 'left', style: 'text_body', margin: [0, 20, 0, 0] }
            ]
          },
          {
            columns: [
              { text: '', alignment: 'left', style: 'text_body', width: '32.5%' },
              { text: 'ЕРӨНХИЙ НЯ-БО   ......................   /Д. Алтанцэцэг/', alignment: 'left', style: 'text_body' }
            ]
          }
        ],
        styles: {
          invoice_description: {
            fontSize: 8
          },
          invoice_header: {
            fontSize: 12,
            bold: true
          },
          text_bold: {
            fontSize: 9,
            bold: true
          },
          text_body: {
            fontSize: 9
          },
          text_body_bold: {
            fontSize: 9,
            bold: true
          },
          text_table_header: {
            fontSize: 9,
            fillColor: '#e6d4b4',
            alignment: 'center'
          },
          text_table_data: {
            fontSize: 9,
            alignment: 'right'
          }
        }
      }

      pdfMake.createPdf(docDefinition).print()
    }
  }
}
</script>

<style scoped>
.invoice_body {
  font-size: 9px;
  width: 610px;
  margin: 20px auto;
}
.invoice_header {
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.invoice_description {
  font-size: 8px;
}
.el-col .border_bottom {
  border-bottom: 1px dashed
}
table {
  width: 100%;
  border: 0.5px solid;
  border-collapse: collapse
}
th {
  border: 0.5px solid;
  padding: 3px;
  background-color: #e6d4b4;
}
td {
  text-align: right;
}
.el-button--warning:hover {
  background-color: #FFB500 !important;
  border-color: #FFB500 !important
}
</style>
